import react from 'react';
import { AppBar, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import NfcIcon from '@mui/icons-material/Nfc';
import StorageIcon from '@mui/icons-material/Storage';
import SystemSecurityUpdateIcon from '@mui/icons-material/SystemSecurityUpdate';
import RouterIcon from '@mui/icons-material/Router';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import TokenIcon from '@mui/icons-material/Token';
import { translate } from "../../i18n/customI18nProvider";
import config from '../../config';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import Swal from 'sweetalert2';
import { clear } from '../../services/storage';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';


const drawerWidth = 240;

const styles = {
    highlightedMenu: {
        // backgroundColor: '#e0e0e0',
        color: 'rgba(0, 0, 0, 0.99)'
    },
    menu: {
        color: 'rgba(0, 0, 0, 0.45)'
    },
    icon: {
        fontSize: '20px'
    }
}

const menu_items = [
    {
        key: 'events',
        name: translate('custom.events'),
        path: '/admin#/events',
        icon: <EventIcon style={styles.icon} />,
        minRight: config.permissions.ADMIN.value
    },
    {
        key: 'users',
        name: translate('custom.users'),
        path: '/v2/admin/users',
        icon: <PeopleIcon style={styles.icon} />,
        minRight: config.permissions.ADMIN.value
    },
    {
        key: 'clients',
        name: translate('custom.clients'),
        path: '/admin#/clients',
        icon: <NfcIcon style={styles.icon} />,
        minRight: config.permissions.ADMIN.value
    },
    {
        key: 'replica_sets',
        name: translate('custom.replica_sets'),
        path: '/admin#/replica_sets',
        icon: <StorageIcon style={styles.icon} />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'latest_app',
        name: translate('custom.latest_app'),
        path: '/admin#/latest_app',
        icon: <SystemSecurityUpdateIcon style={styles.icon} />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'devices',
        name: translate('custom.devices'),
        path: '/v2/admin/devices',
        icon: <RouterIcon style={styles.icon} />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'mypos_terminals',
        name: translate('custom.mypos_terminals'),
        path: '/v2/admin/mypos_terminals',
        icon: <PointOfSaleIcon style={styles.icon} />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'tickets',
        name: translate('custom.tickets'),
        path: '/v2/admin/tickets',
        icon: <LocalActivityIcon style={styles.icon} />,
        minRight: config.permissions.SUPERADMIN.value
    },
    {
        key: 'tokens',
        name: translate('custom.tokens'),
        path: '/v2/admin/tokens',
        icon: <TokenIcon style={styles.icon} />,
        minRight: config.permissions.SUPERADMIN.value
    }
]

// Define your custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: config.baseColor, // Replace with your desired primary color
        },
        secondary: {
            main: '#00ff00', // Replace with your desired secondary color
        },
    },
});

const logout = () => {
    Swal.fire({
        title: translate('custom.are_you_sure_you_want_to_logout'),
        showCancelButton: true,
        confirmButtonText: translate('custom.logout'),
        confirmButtonColor: config.baseColor
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            clear();
            window.location.replace('/login');
        }
    })
}

const drawer = (right, highlighted=null) => (
    <div>
        <Toolbar />
        <Divider />
        <List>
            {menu_items.map((item) => (
                (right >= item.minRight) ? (
                    <Link
                        key={item.key}
                        to={item.path}
                        style={{textDecoration: 'none', color: 'inherit'}}
                    >
                        <ListItem
                            key={item.key}
                            disablePadding
                            style={(highlighted === item.key) ? styles.highlightedMenu : styles.menu}
                        >
                            <ListItemButton style={{paddingTop: 2, paddingBottom: 2}}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ) : null
            ))}
        </List>
        <Divider />
        <ListItemButton
            variant="disabled"
            onClick={logout}
            style={styles.menu}
        >
            <ListItemIcon >
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={translate('custom.logout')} />
        </ListItemButton>
        <Divider />
        <Typography variant="caption" style={{padding: 10}}>
            {config.version}
        </Typography>
    </div>
);

class V2Layout extends react.Component {

    constructor(props) {
        super(props);

        let currentMenu = this.props.currentMenu;

        for (let i = 0; i < menu_items.length; i++) {
            if (menu_items[i].key === currentMenu) {
                currentMenu = menu_items[i];
                break;
            }
        }

        this.state = {
            mobileOpen: false,
            isClosing: false,
            right: localStorage.getItem('right'),
            currentMenu
        };
    }

    handleDrawerClose = () => {
        this.setState({isClosing: true, mobileOpen: false});
    };

    handleDrawerTransitionEnd = () => {
        this.setState({isClosing: false});
    };

    handleDrawerToggle = () => {
        if (!this.state.isClosing) {
            this.setState({mobileOpen: !this.state.mobileOpen});
        }
    };

    render() {

        let title = this.props.title ? this.props.title : null;

        return (
            <ThemeProvider theme={theme}>
            <Grid container style={{ display: 'flex' }}>
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        backgroundColor: config.baseColor,
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            {title ? title : this.state.currentMenu?.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid item xs={12} sm={drawerWidth} style={{ flexShrink: 0 }}>
                    <Drawer
                        variant="temporary"
                        open={this.state.mobileOpen}
                        onTransitionEnd={this.handleDrawerTransitionEnd}
                        onClose={this.handleDrawerClose}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        PaperProps={{
                            style: {
                                backgroundColor: config.backgroundColor
                            }
                        }}
                    >
                        {drawer(this.state.right, this.state.currentMenu?.key)}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                        PaperProps={{
                            style: {
                                backgroundColor: config.backgroundColor
                            }
                        }}
                    >
                        {drawer(this.state.right, this.state.currentMenu?.key)}
                    </Drawer>
                </Grid>
                <Grid item xs={12}
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        padding: '16px',
                        backgroundColor: config.backgroundColor
                    }}
                >
                    <Toolbar />
                    {this.props.children}
                </Grid>
            </Grid>
            </ThemeProvider>
        )
    }
}

export default V2Layout;
